/* src/app/Components/layerListWidget/LayerListWidget.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

.g-WidgetPanel {
  .layer-item {
    color: var(--color-text);
    font-size: var(--font-size_-2);

    .layer-item-container {
      display: flex;
      position: relative;
      width: 100%;
      padding: var(--space_-3) var(--space_-1);
    }

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    .g-icon-link {
      height: auto;
      justify-content: flex-end;

      svg {
        width: 20px;
        height: 8px;
      }
    }
  }
}
