/**
*
* Copyright 2022 GHGSat inc.
* Authors: spectra@ghgsat.com
* This software is not for distribution outside GHGSat organization
*
*/

.g-basic-timeline-panel-wrapper {
  visibility: hidden;
  padding: 15px 24px 15px 5px;

  &.visible {
    visibility: visible;
  }

  .g-basic-timeline-panel {
    display: flex;
    align-items: center;

    .timeline-bar {
      height: 6px;
      flex: 1;
      background-color: var(--color-grey--100);

      input[type="range"] {
        height: 6px;
        background: var(--color-grey--100);
        background: none;
        cursor: pointer;
      }

      .p-slider {
        .p-slider-range {
          background: none;
        }

        .p-slider-handle {
          position: absolute;
          width: 20px;
          height: 20px;
          margin-top: -10px;
          border-radius: 50%;
          background-color: var(--color-primary);
          background-image: url("../../assets/icons/play.svg");
          background-repeat: no-repeat;
          background-position: 4px 1px;
          cursor: pointer;
        }
      }

      &.pause .p-slider .p-slider-handle {
        background-image: url("../../assets/icons/pause.svg");
        background-repeat: no-repeat;
        background-position: 4.5px 3px;
      }
    }

    .timeline-calendar {
      padding: 0 1rem;

      svg {
        path {
          fill: var(--color-primary);
        }
      }
    }

    .play-button {
      position: absolute;
      width: 22px;
      height: 20px;
      margin-top: -6px;
      border-radius: 50%;
      background-color: var(--color-primary);
    }
  }
}
