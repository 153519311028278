/**
 * /*
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */
/** @format */

.generic-loader-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  align-items: center;
  justify-content: center;

  &.visible {
    display: flex;
  }

  &.hidden {
    display: none;
  }

  .curtain {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: var(--color-grey--300);
  }

  .generic-loader {
    position: absolute;
    z-index: 4;

    p {
      color: var(--color-text);
      font-weight: var(--font-weight-bold);
    }
  }
}
