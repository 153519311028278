/* src/app/Assets/Styles/GhgSat/overrides/Prime/_datatable.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

// Table view Datatable
:root {
  --g-datatable-header-height: 40px;
  --g-datatable-header-row-height: 40px;
  --g-datatable-paginator-height: 50px;
  --g-datatable-row-height: 50px;
  --g-datatable-cell-padding: 0 1.5rem 0 0.5rem;
  --datatable-header-height-expanded: 92px;
  --datatable-header-height-collapsed: 52px;
}

// [ 1. ] Prime uses 'background' instead of background-color
.g-Datatable.p-datatable {
  --g-datatable-scroll-height:
    calc(
      100vh - (
        var(--header-height) +
        var(--tabview-header-height) +
        var(--datatable-header-height-collapsed) +
        var(--g-datatable-paginator-height) +
        var(--footer-height)
      )
    );

  .p-datatable-thead {
    font-size: var(--font-size_-1);
  }

  .p-datatable-thead > tr {
    height: var(--g-datatable-header-row-height);
  }

  .p-datatable-scrollable-body {
    min-height: var(--g-datatable-scroll-height);
    max-height: var(--g-datatable-scroll-height);
  }

  &.filter-row-is-collapsed {
    --g-datatable-header-height: var(--datatable-header-height-collapsed);

    .p-datatable-thead tr:not(:first-of-type) {
      display: none;
    }
  }

  &.filter-row-is-expanded {
    --g-datatable-header-height: var(--datatable-header-height-expanded);
    --g-datatable-scroll-height:
      calc(
        100vh - (
          var(--header-height) +
          var(--tabview-header-height) +
          var(--datatable-header-height-expanded) +
          var(--g-datatable-paginator-height) +
          var(--footer-height)
        )
      );
  }

  .p-datatable-thead > tr > th {
    position: relative;
    padding: var(--g-datatable-cell-padding);
    background: var(--color-grey--100); // [ 1 ]
    color: var(--color-orbit-blue);
  }

  .p-highlight {
    .p-column-title {
      font-weight: var(--font-weight-bold);
    }

    .p-sortable-column-icon {
      color: currentColor;
    }
  }

  .p-column-title {
    padding-right: 10px;
    color: inherit;
    font-weight: var(--font-weight-normal);
  }

  .p-sortable-column:not(.p-highlight):hover {
    .p-column-title {
      font-weight: var(--font-weight-bold);
    }
  }

  /* Change the "sort icons" */
  .p-sortable-column-icon {
    &::before {
      font-family: var(--font-family-icon);
      font-size: 8px;
      content: "\e826";
    }

    &.pi-sort-amount-down {
      &::before {
        content: "\e827";
      }
    }

    &.pi-sort-amount-up-alt {
      transform: rotate(180deg);

      &::before {
        content: "\e827";
      }
    }
  }

  .p-datatable-tbody > tr {
    height: var(--g-datatable-row-height);
  }

  .p-datatable-tbody > tr.p-highlight {
    background: var(--surface-c);
    color: var(--color-text);
  }

  .p-datatable-tbody > tr > td {
    overflow: hidden;
    padding: var(--g-datatable-cell-padding);
    font-size: 0.875rem;
    text-overflow: ellipsis;
  }

  .p-paginator {
    height: var(--g-datatable-paginator-height);
    background-color: var(--surface-c);

    .p-paginator-current {
      font-size: 0.75rem;
    }

    .p-dropdown-trigger-icon {
      font-size: 0.75rem;
    }

    .p-paginator-page,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-current {
      min-width: var(--space);
      height: var(--space);
      margin: 0 0.143rem;
    }
  }

  .g-Filter-toggle-button {
    transform: translateY(7px);
  }

  // Prime Datatable Scrollable elements
  //
  // NOTE Prime creates distinct <table> elements for scrollable tables
  // Selectors with '-scrollable-' contains a table, and will be blessed with
  // our own 'g-Datatable__table' class (and ruines it)
  //
  // [ 1. ] Override to prevent header from hiding filter dropdown
  // NOTE: both axis must be set to visible,
  // having one axis set to 'visible' while the other is set anything else
  // makes visible behave like 'auto' (and might show a  scrollbar).
  // [ 2. ] Prime sets a right margin 'on the fly' to
  // .p-datatable-scrollable-header-box to compensate for the scrollbar that we
  // can't  override because of the '%' width. We have to patch the bg color.
  // [3] This fixes the bug with the scrollbar in the table
  .p-datatable-scrollable-header {
    overflow: visible; // [ 1 ]
    background: var(--color-grey--100); // [ 2 ]
  }
}
