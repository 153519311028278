/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.icon-button {
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}
