/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

$default-length: 300px;
$medium-length: 250px;
$small-length: 175px;

.field.g-input-text {
  width: $default-length;
  margin: 15px 0;
  border-color: var(--color-grey-border);

  &.sm {
    width: $small-length;
  }

  &.md {
    width: $medium-length;
  }

  &.max {
    width: 100%;
    max-width: inherit;
  }

  &.valid {
    .g-input {
      border-color: var(--color-green);
    }
  }

  &.error,
  &.altered.error {
    .g-input,
    .help-text {
      border-color: var(--color-red-error);
      color: var(--color-red-error);
    }
  }

  &.disabled {
    &.g-input-text {
      opacity: 1;
      color: var(--color-text);
    }
  }

  &.altered {
    .g-input {
      margin: -1px;
      border-width: 2px;
      border-color: var(--color-grey--500);
      box-shadow: none;
    }
  }

  .g-label {
    width: $default-length;
    margin-bottom: 10px;
    color: var(--color-text);
    font-size: var(--font-size__0);
    font-weight: 500;
  }

  &.sm .g-label {
    width: $small-length;
  }

  &.md .g-label {
    width: $medium-length;
  }

  &.max .g-label {
    width: 100%;
  }

  .g-input {
    width: $default-length;
    color: var(--color-text-input);
    font-size: var(--font-size_-1);
    font-weight: var(--font-weight-normal);

    &.right-icon {
      padding-right: 30px;
    }

    &::placeholder {
      color: var(--color-grey--600);
    }
  }

  &.sm .g-input {
    width: $small-length;
  }

  &.md .g-input {
    width: $medium-length;
  }

  &.max .g-input {
    width: 100%;
  }

  .g-field-end-icon {
    display: flex;
    position: absolute;
    transform: translate(calc($default-length - 25px), -25px);
    justify-content: flex-end;

    &.sm {
      transform: translate(calc($small-length - 25px), -25px);
    }

    &.md {
      transform: translate(calc($medium-length - 25px), -25px);
    }

    svg {
      width: 16px;
      height: 16px;
      pointer-events: none;
    }
  }
}
