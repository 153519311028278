/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.g-esri-override {
  .password-section {
    height: 320px;
    padding: 15px 15px 15px 21px;
  }

  .error-message {
    color: var(--color-red-error);
  }
}
