/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.g-esri-override {
  .global-toast-container {
    .p-toast .p-toast-message {
      border-bottom: none;
    }

    .g-Toast {
      display: flex;
      height: 2.5rem;
      align-items: center;
      margin-right: 1rem;

      .toast-icon {
        width: 1.375rem;
        height: 1.375rem;
        margin: 0.5625rem;

        &[class$="success"] {
          path {
            fill: var(--color-green);
          }
        }

        &[class$="info"] {
          path {
            fill: var(--color-info);
          }
        }

        &[class$="warn"] {
          path {
            fill: var(--color-yellow);
          }
        }

        &[class$="error"] {
          path {
            fill: var(--color-red-error);
          }
        }
      }

      .title {
        color: var(--color-text);
        font-size: var(--font-size_-2);
        white-space: nowrap;
      }

      .content {
        margin-left: 10px;
        padding: var(--space-mouse) 0;
        color: var(--color-text);
        font-size: var(--font-size_-2);
      }
    }
  }
}
