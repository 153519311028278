/* src/app/Components/mapView/legend/Legend.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

$WidgetPanel-padding: var(--space) var(--space__1) var(--space) 0;

.g-Legend-Container {
  // @include inset();

  z-index: 1;
  padding: $WidgetPanel-padding;
  background-color: var(--surface-a);
  transition: all 0.2s ease;

  &.visible {
    transform: translateY(0);
  }

  &.hidden {
    transform: translateY(calc(100%));
  }
}

.g-Legend {
  width: 100%;
  overflow: inherit;
  padding-left: var(--space_-1);

  .layers {
    display: flex;
    justify-content: space-between;

    .layer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-right: 20px;
      padding: var(--space_-4) var(--widget-panel-paddng-X);
    }
  }

  .title {
    margin-bottom: var(--space_-2);
    font-size: var(--font-size_-2);
    text-transform: uppercase;
  }

  .items {
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    &.multi-cols {
      display: flex;
    }

    .item-col {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    .item {
      display: flex;
      align-items: center;
      margin-bottom: var(--space_-3);
    }

    .icon-container {
      display: flex;
      width: 20px;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      // Zone icons
      .icon-zone-square {
        width: 1em;
        height: 1em;
        border: 1px solid var(--surface-a);
        background-color: rgba(242, 37, 20, 0.4);

        &.analytics {
          background-color: rgba(0, 89, 255, 0.5);
        }

        &.other {
          // since we show the border we can reduce 2 pixels from actual
          // width and height to achieve consistent size
          width: calc(1em - 2px);
          height: calc(1em - 2px);
          border-color: rgba(0, 0, 0, 0.5);
          background-color: rgba(141, 141, 141, 0.2);
        }
      }

      // Teal Observation for Catalog Purchase
      .observation-teal {
        path {
          fill: rgba(var(--color-teal-observation-rgb), 0.6);
        }
      }

      // Concentration icons
      .icon-g-concentration-square {
        width: 1em;
        height: 1em;
        border: 1px solid var(--surface-a);
        background-color: rgba(242, 37, 20, 0.4);

        &.l-1 {
          background-color: rgba(50, 10, 94, 0.4);
        }

        &.l-2 {
          background-color: rgba(120, 28, 109, 0.4);
        }

        &.l-3 {
          background-color: rgba(188, 55, 84, 0.4);
        }

        &.l-4 {
          background-color: rgba(237, 105, 37, 0.4);
        }

        &.l-5 {
          background-color: rgba(251, 182, 26, 0.4);
        }

        &.l-6 {
          background-color: rgba(252, 255, 164, 0.4);
        }
      }

      // Predictions icons
      .icon-g-prediction-square {
        width: 1em;
        height: 1em;
        border: 1px solid rgba(77, 59, 96, 0.7);
        background-color: transparent;

        &.l-1 {
          border-width: 6px;
        }

        &.l-2 {
          border-width: 5px;
        }

        &.l-3 {
          border-width: 4px;
        }

        &.l-4 {
          border-width: 3px;
        }

        &.l-5 {
          border-width: 2px;
        }
      }
    }

    .icon {
      width: 20px;
      height: 20px;
      font-size: 1.25rem;

      &.small {
        width: 16px;
        height: 16px;
      }

      &.large {
        width: 28px;
        height: 28px;
      }
    }

    .label {
      color: var(--color-text-secondary);
      font-size: 0.75rem;
    }

    .emissions-scale {
      display: grid;
      height: 100%;
      grid-template-columns: 20px auto;
      grid-template-rows: 2;

      .emission-scale-gradient {
        height: 100%;
        margin-right: 5px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 255, 1) 33.33%, rgba(143, 255, 128, 1) 60%, rgba(255, 250, 71, 1) 75%, rgba(255, 0, 0, 1) 100%);
        filter: brightness(90%) opacity(80%);
      }

      .emission-scale-labels {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
