// Copyright 2022 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

.g-esri-override {
  .btn-layer-visibility {
    display: flex;
    flex: 1;
    align-items: center;
    color: var(--text-disabled);

    .btn-layer {
      display: flex;
      align-items: center;
      font-family: var(--font-family);
      font-size: 12px;
      white-space: nowrap;
      cursor: pointer;

      &.disabled {
        font-family: var(--font-family);
      }

      .g-icon-eye {
        padding-right: 8px;

        svg {
          width: 18px;
          height: 14px;

          path {
            fill: var(--color-primary);
          }
        }
      }
    }

    .layer-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: var(--space_-4);

      svg {
        width: 12px;
        height: 12px;
      }
    }

    .pi-small {
      transform: translateY(1px);
    }

    &.visible {
      color: var(--color-text);
    }

    &.isOutOfScale,
    &.disabled {
      pointer-events: none;

      .btn-layer {
        .g-icon-eye {
          svg {
            path {
              fill: var(--text-disabled);
            }
          }
        }
      }

      .icon-g-info {
        pointer-events: initial;
      }
    }
  }

  .layer-info {
    margin-left: var(--space_-4);
  }

  .btn-remove-layer {
    margin: 0 0 0 auto;
    color: var(--color-red);
  }

  .btn-get-layer-uri {
    margin: 0 0 0 auto;
    color: var(--color-primary);
    font-size: var(--font-size_-4);

    &.disabled {
      opacity: 1;
      color: var(--text-disabled);
    }
  }
}
