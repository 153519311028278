/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.QandA-div {
  padding: 5px 0;
  color: var(--surface-b);
  font-family: var(--font-family);

  h4 {
    padding: 5px 0;
    font-family: var(--font-family);
    font-size: var(--font-size__0);
    font-weight: var(--font-weight-bold);
  }

  p {
    font-family: var(--font-family);
    font-size: var(--font-size_-1);
  }

  a {
    color: var(--surface-b);
  }
}
