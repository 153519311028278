/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 * @format
 */

.button-container {
  display: inline;
  margin: 0 10px 0 0;
}

.share-view-title {
  color: var(--color-text-input);
  font-size: var(--font-size_-1);
}
