// Copyright 2022 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

.g-map-widget-container {
  display: grid;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: min-content;
  grid-template-areas:
    "top-left top-center top-right"
    "middle-left middle-center middle-right"
    "bottom-left bottom-center bottom-right";
  pointer-events: none;

  .g-clickable-widget {
    pointer-events: all;
  }

  .g-widget-top-left {
    grid-area: top-left;
  }

  .g-widget-top-center {
    grid-area: top-center;
  }

  .g-widget-top-right {
    grid-area: top-right;
  }

  .g-widget-middle-left {
    grid-area: middle-left;
  }

  .g-widget-middle-center {
    grid-area: middle-center;
  }

  .g-widget-middle-right {
    grid-area: middle-right;
  }

  .g-widget-bottom-left {
    grid-area: bottom-left;
  }

  .g-widget-bottom-center {
    grid-area: bottom-center;
  }

  .g-widget-bottom-right {
    grid-area: bottom-right;
  }
}
