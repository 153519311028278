// Copyright 2022 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

@import "base";
@import "layout";
@import "theme";
@import '../../../../node_modules/primeflex/primeflex.css';
@import '../../../../node_modules/primereact/resources/primereact.min.css';
@import './vendors//primereact/components';
@import './vendors/primereact/overrides/';

.g-map-layout {
  position: absolute;
  z-index: 1;
}

.g-list-layout {
  position: relative;
  min-height:
    calc(
      100vh - var(--header-height)
    );
  z-index: 2;
  background-color: var(--surface-a);
}

.g-generic-loader {
  display: grid;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  align-content: center;
  justify-content: center;
  pointer-events: none;
}

.main-tab-menu-wrapper {
  display: flex;
  width: 100vw;
  height: 40px;
  justify-content: space-between;
  padding-right: 4px;

  .p-tabmenu {
    &.main-tab-menu {
      .p-tabmenu-nav {
        height: 40px;
        border-width: 0 0 1px 0;

        .p-tabmenuitem {
          height: 40px;

          .p-menuitem-link {
            height: 40px;
            border-width: 0 0 3px 0;
            padding: 0.75rem 0.8rem !important;
            background: #ffffff;
            color: var(--color-orbit-blue);
            font-size: 0.875rem;
            font-weight: normal;
          }

          &:not(.p-highlight):not(.p-disabled):hover {
            .p-menuitem-link {
              color: var(--color-orbit-blue);
            }
          }

          &.p-highlight {
            .p-menuitem-link {
              border-color: var(--color-orbit-blue);
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.g-map-view-layout {
  display: grid;
  width: 100vw;
  min-height: var(--min-map-height);
  overflow: hidden;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: 1fr min-content;
  grid-template-areas: "appBar map sideBar" "appBar bottomPanel sideBar";

  .g-map-view-map-container {
    display: flex;
    position: relative;
    overflow: hidden;
    flex: 1;
    grid-area: 'map';

    .g-map-view-map {
      width: 100%;
      height: 100%;

      .g-quicktimeline-widget {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        margin: 20px;
      }

      .g-map-widgets-bottom-right {
        position: absolute;
        right: 1.75rem;
        bottom: 8.5rem;
        width: 32px;
        z-index: 4;

        .button-share-map {
          display: flex;
          padding: 7px 9px;
          background-color: var(--surface-a);
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
