/* src/app/Assets/Styles/ghgSat/overrides/Prime/_badges.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

.g-esri-override {
  .p-checkbox {
    vertical-align: text-bottom;
  }
}
