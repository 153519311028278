/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.g-esri-override {
  .my-profile-section {
    padding: 15px 15px 15px 21px;

    .change-password-section {
      margin-bottom: 15px;

      .header {
        width: 300px;
        margin-bottom: 30px;
        color: var(--color-text);
        font-size: var(--font-size__0);
        font-weight: 500;
      }

      .link {
        color: var(--color-primary);
        text-decoration: underline;
      }
    }

    .error-message {
      color: var(--color-red-error);
    }
  }
}
