/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.g-prime-override {
  .p-dropdown-panel {
    // Account for being inside module
    &.module {
      margin: 6px;
    }

    &.small {
      .p-dropdown-items .p-dropdown-item {
        font-size: var(--font-size_-2);
      }
    }

    &.tiny {
      .p-dropdown-items .p-dropdown-item {
        font-size: var(--font-size_-2);
      }
    }
  }

  .p-dropdown {
    &.small {
      height: 2rem;

      .p-inputtext {
        font-size: var(--font-size_-2);
      }

      .p-dropdown-trigger {
        width: 1.65rem;
        font-size: var(--font-size_-2);
      }
    }

    &.tiny {
      .p-inputtext {
        padding: 6px;
        font-size: var(--font-size_-2);
      }

      .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        font-size: var(--font-size_-2);
      }

      .p-dropdown-trigger {
        width: 1.25rem;
        font-size: var(--font-size_-2);
      }
    }

    &.dark {
      border: 1px solid var(--surface-a-500);
      background-color: var(--color-primary-background);

      .p-dropdown-label {
        color: var(--surface-a-500);
      }

      .p-dropdown-trigger {
        color: var(--surface-a-500);
      }

      &.p-inputwrapper-filled {
        border: 1px solid rgba(var(--surface-a-rgb), 0.7);

        .p-dropdown-label {
          color: var(--surface-a);
        }

        .p-dropdown-trigger {
          color: var(--surface-a);
        }
      }
    }
  }
}
