// Copyright 2022 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

.g-basemap-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0 1rem;
  gap: 0.5rem;
  background-color: var(--surface-a);
}
