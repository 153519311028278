/* src/app/Assets/Styles/ghgSat/overrides/Prime/_badges.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

._color-surface-a {
  color: var(--surface-a);
}

._primary-color {
  color: var(--primary-color);
}

.color-red {
  color: var(--color-red);
}

._color-purple-observation-rgb {
  &.O-6 {
    color: rgba(var(--color-purple-observation-rgb), 0.6);
  }
}

// mainly used in the footer legend about catalog observation
._color-teal-observation-rgb {
  &.O-6 {
    color: rgba(var(--color-teal-observation-rgb), 0.6);
  }
}
