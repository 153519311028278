/* src/app/Components/appBar/AppBar.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

.g-map-view-appbar-container {
  display: flex;
  grid-area: appBar;

  .g-map-view-appbar {
    display: flex;
    width: var(--appbar-length);
    z-index: 2;
    flex-direction: column;
    justify-content: space-between;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: var(--border-a);
    background-color: var(--color-grey--100);
    color: var(--color-orbit-blue);

    .g-appbar-item {
      display: flex;
      width: var(--appbar-length);
      justify-content: center;

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }

      .blue-alert {
        position: absolute;
        top: 37px;
        left: 23px;
      }
    }

    .g-bottom-panel-button-group {
      background-color: var(--color-grey--300);
    }
  }

  .appbar-side-panel {
    height: calc(100vh - var(--header-height) - var(--footer-height));
  }

  .g-map-view-left-panel {
    width: var(--left-panel-width);
    height: calc(100vh - var(--header-height) - var(--footer-height));
    overflow: auto;
  }
}

.g-tippy {
  background-color: red;
}
