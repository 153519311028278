/* src/app/Components/footer/Footer.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

.g-Footer {
  display: flex;
  width: 100%;
  z-index: 2;
  align-items: center;
  padding: 0 var(--space_-3);
  background-color: var(--color-grey--100);
  grid-area: footer;

  .g-attribution-container {
    display: flex;
    width: 100%;

    .right {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: flex-end;
    }

    .g-attribution {
      display: flex;
      align-items: center;
    }

    .terms-of-use {
      font-size: var(--font-size_-3);
      font-weight: var(--font-weight-normal);
      text-align: right;
      text-decoration: none;
      white-space: nowrap;
    }

    .spacer {
      padding: 0 var(--space_-3);
      color: var(--color-grey--500);
    }

    .g-powered-by {
      margin: 0;
      color: var(--color-grey--600);
      font-size: var(--font-size_-3);
      font-weight: var(--font-weight-normal);
      text-align: right;
      white-space: nowrap;
    }
  }
}
