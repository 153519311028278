/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.app-bar-icon-button {
  display: flex;
  width: 40px;
  height: 34px;
  align-items: center;
  justify-content: center;

  .icon-circle {
    display: flex;
    width: 34px;
    height: 34px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;

    &.selected {
      background-color: var(--color-orbit-blue);

      path {
        fill: var(--color-grey--100);
      }
    }
  }

  path {
    fill: var(--color-orbit-blue);
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}
