/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.g-NewsPanel {
  width: 420px;
  height: 100%;
  overflow: hidden;
  background-color: var(--surface-a);

  .g-NewsPanel-Section {
    height: 100%;

    .g-NewsPanel-Articles {
      height: 100%;
      overflow-y: auto;
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
    background: var(--color-grey--200);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: var(--color-grey--400);
  }

  .p-accordion {
    .blue-dot {
      position: absolute;
      top: 6px;
      left: 3px;
      width: 12px;
      height: 13px;
    }

    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover {
      .p-accordion-header-link {
        color: var(--color-black);
      }
    }

    .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
      background: none;
    }

    .p-accordion-header:not(.p-disabled) {
      .p-accordion-header-link:focus {
        box-shadow: none;
      }
    }

    .p-accordion-tab {
      font-family: var(--font-family);

      .p-accordion-header {
        .p-accordion-header-link {
          height: 42px;
          padding-left: 15px;
          background-color: #ffffff;
          font-family: var(--font-family);
          font-size: var(--font-size_-1);
          font-weight: var(--font-weight-bold);
        }
      }

      .p-accordion-content {
        background-color: var(--color-grey--50);
      }

      &.p-accordion-tab-active {
        .p-accordion-header-link {
          background-color: var(--color-grey--50);
        }

        .p-accordion-header {
          border: none;
          background-color: var(--color-grey--50);

          .p-accordion-header-link {
            color: var(--color-black);
          }
        }
      }
    }
  }

  .accordion-button {
    margin: 0 0 10px 0;
    font-size: var(--font-size_-1);
    text-transform: uppercase;
  }

  .accordion-summary-wrapper {
    display: grid;
    margin-bottom: 1rem;

    .accordion-text {
      margin-right: 1rem;
      padding: 0;
      color: var(--color-black);
      font-family: var(--font-family);
      font-size: var(--font-size_-2);
    }

    img {
      width: 200px;
      margin: auto;
    }

    .clickable {
      cursor: pointer;
    }
  }

  .with-columns {
    grid-template-columns: 1fr 1fr;
  }

  .accordion-info-link {
    color: var(--color-primary);
    font-family: var(--font-family);
    font-size: var(--font-size_-2);
    font-style: italic;
    text-decoration: underline;
  }
}
