/* src/app/Assets/Styles/GhgSatApp.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

/**
  Overrides primeflex.css
*/
.g-prime-override {
  .p-field {
    > label {
      color: var(--color-orbit-blue);
    }
  }
}
