// Copyright 2022 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

$panel-header-height: 83px;

.g-side-panel {
  height: 100%;
  overflow: hidden;
  background-color: var(--surface-c);
  transition: width 250ms ease-in-out;

  .g-side-panel-header {
    width: 100%;
    max-width: inherit;
    height: $panel-header-height;
    padding: 1.5rem 1rem;
    background-color: var(--surface-a);

    .heading {
      font-family: var(--font-family-narrow);
      font-size: 1.3rem;
      font-weight: 600;
    }
  }

  .g-side-panel-body {
    min-width: 300px;
    height: 100%;

    &.pad-header {
      height: calc(100% - $panel-header-height);
    }
  }
}
