/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

[class*="g-icon-"] {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
