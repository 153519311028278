/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
/// Border of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBorder: 0 none;

/// Background of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBg: $panelContentBg;

/// Box shadow of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContainerShadow: 0 3px 14px 0 rgba(0, 0, 0, 0.35);

/// Background of a dialog header
/// @group overlay
$dialogHeaderBg: var(--color-grey--100);

/// Border of a dialog header
/// @group overlay
$dialogHeaderBorder: none;

/// Text color of a dialog header
/// @group overlay
$dialogHeaderTextColor: var(--color-text);

/// Font weight of a dialog header
/// @group overlay
$dialogHeaderFontWeight: var(--font-weight-bold);

/// Font size of a dialog header
/// @group overlay
$dialogHeaderFontSize: var(--font-size__0);

/// Padding of a dialog header
/// @group overlay
$dialogHeaderPadding: 0 20px;

/// Padding of a dialog content
/// @group overlay
$dialogContentPadding: 20px;

/// Border of a dialog footer
/// @group overlay
$dialogFooterBorder: 2px solid var(--surface-g);

/// Padding of a dialog footer
/// @group overlay
$dialogFooterPadding: 0 20px;

/// Background of a tooltip
/// @group overlay
$tooltipBg: $textColor;

/// Text color of a tooltip
/// @group overlay
$tooltipTextColor: #ffffff;

/// Padding of a tooltip
/// @group overlay
$tooltipPadding: $inputPadding;
