/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.p-input-icon-right > i {
  transform: translateY(-50%);
  margin-top: 0;
}

.p-input-icon-left > i {
  transform: translateY(-50%);
  margin-top: 0;
}

.p-inputtext {
  &.small {
    font-size: var(--font-size_-2);
  }

  &.tiny {
    padding: 6px;
    font-size: var(--font-size_-2);
  }

  &.dark {
    border: 1px solid var(--surface-a-500);
    background-color: var(--color-primary-background);

    &::placeholder {
      color: var(--surface-a-500);
    }

    &.p-filled {
      border: 1px solid rgba(var(--surface-a-rgb), 0.7);
      color: var(--surface-a);
    }

    &:enabled:hover {
      border: 1px solid rgba(var(--surface-a-rgb), 0.7);
    }

    &:enabled:focus {
      border-color: var(--color-primary-darker);
      box-shadow: 0 0 0 0.05rem var(--color-primary-darker);
    }
  }
}
