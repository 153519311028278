/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.alertBanner {
  display: flex;
  position: absolute;
  top: 3.5em;
  width: 100%;
  height: 3.45rem;
  justify-content: center;
  margin: auto;
  padding: 10px;

  &.blue {
    background-color: #e6f1fb;
  }

  .icon-div {
    display: flex;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    margin: 0.32rem 1.125rem;

    svg {
      width: 20px;
      height: 20px;
    }

    path {
      fill: var(--color-primary);
    }
  }

  .title-div {
    flex-grow: 1;
    margin: auto auto auto 0;
    font-family: var(--font-family);
    font-size: var(--font-size_-2);

    .title {
      display: inline-block;
      font-weight: var(--font-weight-bold);
    }

    p {
      display: inline-block;
      padding-left: 5px;
    }
  }

  .action-button-div {
    margin-right: 1rem;
  }

  .cancel-more-info-div {
    margin: auto 1rem auto auto;
  }
}
