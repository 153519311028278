/* src/app/Components/mapTools/WidgetPanel.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

$widget-panel-padding-X: var(--spcace_-1);
$widget-panel-padding-Y: 20px;
$widget-panel-line-margin-Y: 10px;
$widget-panel-line-height: 16px;
$widget-panel-row-height: var(--vertical-grid-baseline);

@mixin padding ($axe) {
  @if ($axe == "X") {
    padding-right: $widget-panel-padding-X;
    padding-left: $widget-panel-padding-X;
  }

  @else if($axe == "Y") {
    padding-top: $widget-panel-padding-Y;
    padding-bottom: $widget-panel-padding-Y;
  }
}

// Widget global variables to be used by children widgets
:root {
  --widget-panel-padding-X: var(--spcace_-1);
  --widget-panel-padding-Y: var(--spcace_-1);
  --widget-panel-line-margin-Y: var(--spcace_-3);
  --widget-panel-row-height: var(--space);
}

.g-WidgetPanel {
  width: 100%;
  background-color: var(--surface-a);
  color: var(--color-text);
  line-height: $widget-panel-line-height;
  letter-spacing: var(--font-tracking);
}
//
// 2. Other Styling provided by .p-button.p-link classes selector
.g-WidgetPanel-Section {
  height: max-content;
  background-color: var(--surface-a);

  &.with-border {
    border-bottom: 1px solid var(--color-grey--300);
    padding-bottom: 15px;
  }
}

.g-WidgetPanel-Header,
.g-WidgetPanel-SubHeader {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  background-color: inherit;
}

.g-WidgetPanel-Header {
  display: flex;
  height: var(--space__2);
  align-items: center;
  justify-content: space-between;
  padding: var(--space_-3) var(--space_-1);

  .btn-actions {
    margin: 0 0 0 auto;
  }
}

.g-WidgetPanel-SubHeader {
  height: var(--vertical-grid-baseline);
  padding: 0 var(--space_-1);
}

.g-WidgetPanel .g-GridList {
  display: grid;
  grid-auto-rows: var(--widget-panel-row-height);

  .Grid-row {
    display: flex;
    align-items: center;
  }
}

.g-Disclosure {
  display: grid;
  position: relative;
  width: var(--side-bar-width);
  min-width: var(--side-bar-min-width);
  overflow: hidden;
  z-index: 0;
  padding-bottom: var(--widget-panel-padding-Y);
  background-color: inherit;
  grid-template-columns: minmax(var(--side-bar-min-width), var(--side-bar-width)) minmax(0, 1fr);
  grid-template-areas: "summary details";
  transition: width 250ms ease-in-out;

  &.is-expanded {
    width: 100vw;
  }
}

.g-Disclosure-Details {
  display: block;
  grid-area: details;
  overflow-x: hidden;

  > * {
    opacity: 1;
    transition: opacity 100ms ease-in-out 250ms;
  }

  .g-Disclosure-Header {
    padding: 0;
  }
}

.g-Disclosure:not(.is-expanded) .g-Disclosure-Details > * {
  opacity: 0;
}

.g-Disclosure-Summary {
  grid-area: summary;
}

.has-ruler::after {
  display: block;
  width: 100%;
  height: 1px;
  margin: var(--space-kitten) 0;
  background-color: var(--color-grey--100);
  content: "";
}

.has-shadow-Y {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: auto;
  box-shadow: var(--ui-box-shadow-Y);
  pointer-events: none;
}
