/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
@mixin icon-override($icon) {
  &::before {
    content: $icon;
  }
}

@mixin focused() {
  outline: $focusOutline;
  outline-offset: $focusOutlineOffset;
  box-shadow: $focusShadow;
}

@mixin focused-input() {
  @include focused();

  border-color: $inputFocusBorderColor;
}

@mixin focused-listitem() {
  outline: $focusOutline;
  outline-offset: $focusOutlineOffset;
  box-shadow: $inputListItemFocusShadow;
}

@mixin invalid-input() {
  border-color: $inputErrorBorderColor;
}

@mixin menuitem-link {
  border-radius: $menuitemBorderRadius;
  padding: $menuitemPadding;
  color: $menuitemTextColor;
  transition: $listItemTransition;
  user-select: none;

  .p-menuitem-text {
    color: $menuitemTextColor;
  }

  .p-menuitem-icon {
    margin-right: $inlineSpacing;
    color: $menuitemIconColor;
  }

  .p-submenu-icon {
    color: $menuitemIconColor;
  }

  &:not(.p-disabled):hover {
    background: $menuitemHoverBg;

    .p-menuitem-text {
      color: $menuitemTextHoverColor;
    }

    .p-menuitem-icon {
      color: $menuitemIconHoverColor;
    }

    .p-submenu-icon {
      color: $menuitemIconHoverColor;
    }
  }

  &:focus {
    @include focused-listitem();
  }
}

@mixin horizontal-rootmenuitem-link {
  border-radius: $horizontalMenuRootMenuitemBorderRadius;
  padding: $horizontalMenuRootMenuitemPadding;
  color: $horizontalMenuRootMenuitemTextColor;
  transition: $listItemTransition;
  user-select: none;

  .p-menuitem-text {
    color: $horizontalMenuRootMenuitemTextColor;
  }

  .p-menuitem-icon {
    margin-right: $inlineSpacing;
    color: $horizontalMenuRootMenuitemIconColor;
  }

  .p-submenu-icon {
    margin-left: $inlineSpacing;
    color: $horizontalMenuRootMenuitemIconColor;
  }

  &:not(.p-disabled):hover {
    background: $horizontalMenuRootMenuitemHoverBg;

    .p-menuitem-text {
      color: $horizontalMenuRootMenuitemTextHoverColor;
    }

    .p-menuitem-icon {
      color: $horizontalMenuRootMenuitemIconHoverColor;
    }

    .p-submenu-icon {
      color: $horizontalMenuRootMenuitemIconHoverColor;
    }
  }

  &:focus {
    @include focused-listitem();
  }
}

@mixin placeholder {
  ::input-placeholder {
    @content;
  }

  :placeholder {
    @content;
  }

  ::placeholder {
    @content;
  }

  :input-placeholder {
    @content;
  }
}

@mixin scaledPadding($val, $scale) {
  padding: nth($val, 1) * $scale nth($val, 2) * $scale;
}

@mixin scaledFontSize($val, $scale) {
  font-size: $val * $scale;
}

@mixin nested-submenu-indents($val, $index, $length) {
  ul {
    li {
      a {
        padding-left: $val * ($index + 1);
      }

      @if $index < $length {
        @include nested-submenu-indents($val, $index + 2, $length);
      }
    }
  }
}

@mixin action-icon() {
  width: $actionIconWidth;
  height: $actionIconHeight;
  border: $actionIconBorder;
  border-radius: $actionIconBorderRadius;
  background: $actionIconBg;
  color: $actionIconColor;
  transition: $actionIconTransition;

  &:enabled:hover {
    border-color: $actionIconHoverBorderColor;
    background: $actionIconHoverBg;
    color: $actionIconHoverColor;
  }

  &:focus {
    @include focused();
  }
}
