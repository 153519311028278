/* src/app/Assets/Styles/ghgSat/overrides/Prime/_menu.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

// Visual adjustment with the logo shape edge instead of the container
$pop-upVisualAdjustmentOffset: 10px;

.p-menu {
  border-radius: 0;

  .p-menuitem-link {
    display: flex;
    align-items: center;
    border-radius: 0;
    line-height: 20px;
  }
}

.g-Menu-Popup,
.g-Menu-Popup.p-menu-overlay {
  top: var(--top-bar-height) !important;
  right: 0;
  left: inherit !important;
  width: ($menuWidth + pxtorem($pop-upVisualAdjustmentOffset)); // Compensation for the left-side visual adjustment
  padding: 0;
}

.g-Menu-Popup.p-menu-overlay.theme-dark {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .p-menuitem {
    padding: 0;
    font-size: var(--font-size_-1);

    &:not(:first-child) {
      border-top: 1px solid #dddcdc;
    }
  }

  .p-menuitem-link {
    height: 45px;
    padding: 0 1em 0 var(--space_-1);
    color: var(--color-text);

    .p-menuitem-text {
      color: var(--color-text);
      font-family: var(--font-family-narrow);
      font-weight: var(--font-weight-normal);
      line-height: 20px;
    }

    .p-menuitem-icon {
      margin-top: 0;
      margin-right: 0.875rem;
      margin-bottom: 0;
      margin-left: -0.125em; // Optical adjustment for alignement with profile-info-container
      color: var(--surface-a-500);
      font-family: var(--font-family-icon);
      font-size: var(--font-size__0);
    }

    .color-red {
      color: var(--color-red);
    }

    .p-menuitem-icon::before {
      margin: 0;
    }
  }
}

.p-tieredmenu {
  .p-menuitem-link {
    font-size: 0.75rem;
  }
}
