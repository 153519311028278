/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.g-prime-override {
  .p-calendar {
    border: 1px solid #ced4da;

    &.small {
      height: 2rem;

      .p-inputtext {
        font-size: var(--font-size_-2);
      }

      .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        font-size: var(--font-size_-2);
      }

      .p-datepicker-trigger {
        font-size: var(--font-size_-1);
      }
    }

    &.icon-input-reverse {
      flex-direction: row-reverse;

      .p-button.p-button-icon-only {
        width: 20px;
      }
    }

    .p-inputtext {
      border: none;
    }

    .p-datepicker-trigger {
      border: none;
      background-color: transparent;
      color: var(--color-primary);
    }
  }
}

.p-calendar {
  [class*="pi-chevron"] {
    width: 9px;
    height: 12px;
    background-image: url("../../../../icons/chevron.svg");

    &.pi-chevron-right {
      transform: rotate(180deg);
    }
  }
}
