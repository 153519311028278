/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.modal-body {
  max-height: 80vh;
  flex-grow: 1;
  padding: 1rem 2rem;
  overflow-y: auto;
}
