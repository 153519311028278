// Copyright 2022 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

.g-quicktimeline-container {
  display: flex;
  width: 200px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 25px;
  border-color: var(--surface-e);
  background-color: var(--surface-a);

  .quicktimeline-button {
    padding: 16px;
  }

  .quicktimeline-info {
    flex: 1;
    text-align: center;

    .quicktimeline-header {
      font-family: var(--font-family-narrow);
      font-size: 13px;
      font-weight: 600;
    }

    .quicktimeline-date {
      color: var(--color-text-secondary);
      font-size: 10px;
    }
  }
}
