/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.g-upgrade-form-container {
  display: flex;

  .g-upgrade-form-info-container {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;

    .form-subheader {
      margin: 0.5rem 0;
      font-family: var(--font-family-narrow);
      font-size: var(--font-size__0);
      font-weight: var(--font-weight-bold);
    }

    ul {
      list-style-type: disc;
      margin-left: 1.5rem;

      li {
        margin: 1rem 0;
      }
    }

    .g-upgrade-form-additional-info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-around;
      font-size: 0.875rem;
      font-style: italic;

      &__error {
        color: var(--color-red-error);
      }
    }
  }

  .g-upgrade-form {
    padding-left: 1rem;

    &__upgrade-input {
      margin-bottom: 2rem;
    }
  }
}

.g-form-submitted-container {
  color: var(--color-orbit-blue);
  font-family: var(--font-family-montserrat);
  text-align: center;

  span {
    display: block;
  }

  &__title {
    padding: 15px;
    font-size: var(--font-size__3);
    font-weight: 800;
    text-transform: uppercase;
  }

  &__subtitle {
    padding: 15px;
    font-size: 1.125rem;
    font-weight: var(--font-weight-normal);
  }

  &__body-text {
    padding: 15px;
    font-size: var(--font-size_-2);
    font-weight: var(--font-weight-noraml);
  }
}
