/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.p-tooltip {
  box-shadow: none;
  pointer-events: none;

  .p-tooltip-text {
    padding: 10px;
    background-color: var(--color-primary-background);
    font-size: var(--font-size_-2);
    box-shadow: none;
  }
}
