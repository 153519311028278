// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization
//@use "variables";
@import "../mixins";
@import '../../../mixins/colors';

.p-button {
  border-radius: 5px;
  font-family: var(--font-family-narrow);
  font-weight: var(--font-weight-bold);

  &.p-button-md {
    @include scaledFontSize($fontSize, $scaleMD);
    @include scaledPadding($buttonPadding, $scaleMD);

    .p-button-icon {
      @include scaledFontSize($primeIconFontSize, $scaleMD);
    }
  }

  &.color {
    @include colors;
  }

  &.p-button-icon-only {
    &.width-auto {
      width: auto;
    }
  }

  &.p-button-link {
    padding: 0;
    color: var(--color-primary);
    font-family: var(--font-family);
    font-weight: var(--font-weight-normal);
    text-decoration: none;

    &:enabled:focus {
      color: var(--primary-color);
      font-family: var(--font-family);
      font-weight: var(--font-weight-normal);
      box-shadow: none;
    }

    .icon {
      font-size: var(--font-size_-1);
      text-decoration: none;
    }

    &.width-auto {
      width: auto;
    }

    &.padded {
      padding: $buttonPadding;
    }
  }

  &.btn-info-small {
    color: var(--color-text-secondary);
    font-size: 0.875rem;
  }

  &.button-g-esri-widget {
    width: 32px;
    height: 32px;
    border-radius: 0;
    background-color: var(--surface-a);
    color: var(--color-orbit-blue);
    font-size: 12px;
    box-shadow: var(--ui-box-shadow-Y);

    &:focus,
    &:active,
    &:enabled:hover,
    &:enabled:active,
    &:hover {
      background-color: var(--surface-a);
    }
  }

  &.g-Round-Tab-btn {
    display: flex;
    width: var(--appbar-btn-length);
    height: var(--appbar-btn-length);
    overflow: visible;
    align-items: center;
    justify-content: center;
    border-radius: var(--appbar-btn-length);
    padding: 0;
    background-color: transparent;
    transition: all 0.2s ease;

    &:active,
    &.is-active {
      background-color: var(--color-orbit-blue);
      color: var(--surface-a);
    }

    &.is-dimmed {
      opacity: 0.5;
    }

    .icon {
      position: relative;
      color: inherit;
      font-size: 1.25rem;
    }

    .label {
      display: none;
      font-size: 0.5rem;
    }
  }
}

// Reset button styling when you want the button behaviour without styling
.btn-reset-styles {
  width: auto;
  margin: 0;
  outline: none;
  border: none;
  padding: 0;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  text-align: inherit;
  cursor: pointer;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  &:focus,
  &:active,
  &:enabled:hover,
  &:enabled:active,
  &:hover {
    border: none;
    background: transparent;
    color: inherit;
    box-shadow: none;
  }
}
