/* src/app/Assets/Styles/ghgSat/overrides/Prime/_accordion.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization
.p-accordion {
  .p-accordion-header {
    .p-accordion-header-link {
      height: var(--vertical-grid-baseline);
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 0 var(--space_-1);
      color: var(--text-color-secondary);
      font-family: var(--font-family-narrow);
      font-size: var(--font-size_-1);
      font-weight: var(--font-weight-bold);

      .p-accordion-toggle-icon {
        margin-right: 0;
        color: var(--color-primary);
        font-size: 0.675rem;
      }
    }
  }

  .p-accordion-content {
    border: $accordionContentBorder;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    padding: $accordionContentPadding;
    background: $accordionContentBg;
    color: $accordionContentTextColor;
  }
}
