/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
/** @format */

/// Padding of an inplace element
/// @group misc
$inplacePadding: $inputPadding;

/// Background of an inplace element in hover state
/// @group misc
$inplaceHoverBg: #e9ecef;

/// Text color of an inplace element in hover state
/// @group misc
$inplaceTextHoverColor: $textColor;

/// Background of a badge
/// @group misc
$badgeBg: var(--color-primary);

/// Text color of a badge
/// @group misc
$badgeTextColor: var(--color-primary-text);

/// Minimum width of a badge
/// @group misc
$badgeMinWidth: 1.5rem;

/// Height of a badge
/// @group misc
$badgeHeight: 1.5rem;

/// Font weight of a badge
/// @group misc
$badgeFontWeight: 700;

/// Font size of a badge
/// @group misc
$badgeFontSize: 0.75rem;

/// Padding of a badge
/// @group misc
$tagPadding: 0.25rem 0.4rem;

/// Height of a progress bar
/// @group misc
$progressBarHeight: 2px;

/// Border of a progress bar
/// @group misc
$progressBarBorder: 0 none;

/// Background of a progress bar
/// @group misc
$progressBarBg: transparent;

/// Background of a progress bar value
/// @group misc
$progressBarValueBg: var(--surface-b);
