// Copyright 2022 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

.g-radio-button {
  min-width: 10rem;

  .g-radio-button-input {
    display: inline-block;
    position: relative;
    top: 0.125rem;
    width: 1rem;
    height: 1rem;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    border: 2px solid var(--color-text-secondary);
    border-radius: 50%;
    cursor: pointer;

    &::after {
      display: block;
      position: relative;
      top: 0.0875rem;
      left: 0.08rem;
      width: 0.6rem;
      height: 0.6rem;
      margin: 0;
      border-radius: 50%;
      background-color: transparent;
      content: "";
    }

    &:checked {
      border: 2px solid var(--color-primary);

      &::after {
        background-color: var(--color-primary);
      }
    }
  }

  .g-radio-button-label {
    padding-left: 0.5rem;
    font-size: 0.875rem;
    cursor: pointer;
  }
}
