/* src/app/Components/layerListWidget/LayerListWidget.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

.g-map-view-sidebar-right {
  display: flex;
  overflow: visible;
  grid-area: sideBar;

  &.visible {
    width: calc(var(--right-panel-border-width) + var(--right-panel-width));
  }

  &.disabled {
    .sidebar-right--border {
      height: 100%;
      background-color: var(--text-disabled);

      .sidebar-right-arrow-wrapper {
        background-color: var(--text-disabled);
        cursor: default;
      }
    }
  }

  .sidebar-right--border {
    width: 8px;
    background-color: var(--color-primary-background);

    .sidebar-right-arrow-wrapper {
      display: flex;
      position: relative;
      top: 50%;
      width: 28px;
      height: 28px;
      z-index: 4;
      transform: translateX(-24px);
      align-items: center;
      justify-content: center;
      border-radius: 50px 0 0 50px;
      background-color: var(--color-primary-background);
      cursor: pointer;
      // arrow icon
      .arrow {
        width: 20px;
        height: 20px;

        &.closed {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .sidebar-right-content-wrapper {
    overflow: hidden;
    flex-grow: 1;
  }
}
