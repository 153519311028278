/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.g-prime-override {
  .p-datepicker {
    .p-datepicker-buttonbar {
      padding: var(--space_-4) 0;
    }
  }
}
