// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

// Visual adjustment with the logo shape edge used by .g-Menu-Popup
$pop-upVisualAdjustmentOffset: 10px;

// Width of the logo container, also used by .g-Menu-Popup for positioning
$LogoWidth: 120px;

.g-Top-Appbar {
  display: flex;
  position: relative;
  width: 100vw;
  z-index: 3;
  justify-content: space-between;
  background: var(--color-primary-background) url('../../assets/images/bg-topbar.svg') no-repeat;
  background-size: cover;
  grid-area: masthead;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

  &-Section--left {
    display: flex;
    position: relative;
  }

  &-Section--right {
    display: flex;
    position: relative;

    .g-arrow {
      width: 16px;
      height: 16px;
      transform: rotate(90deg);
    }
  }

  &-Section--envelope {
    width: 30px;

    .g-icon-envelope {
      transform: scale(120%);
      color: var(--color-primary-text);
    }
  }

  &-Section--help {
    width: 70px;

    .g-icon-help {
      padding: 0 12px;

      path {
        fill: var(--color-primary-text);
      }
    }
  }
  
  .limit-size{
    text-overflow: ellipsis;
    max-width: 48ch;
    overflow: hidden;
    display: block;
  }

  &-Logo-container {
    display: flex;
    position: relative;
    width: $LogoWidth;
    align-items: center;
    padding-left: 20px;
  }

  &-Section--profile {
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 22px;
  }

  .user-avatar {
    display: flex;
    min-width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.4);
    color: var(--color-primary-text);
  }

  &-Section--Profile-info-wrapper {
    position: relative;
    width: 80%;
    margin-right: 5px;
  }

  &-Section--Profile-info {
    margin: 0;
    color: var(--color-primary-text);
    font-family: var(--font-family-narrow);
    font-size: var(--font-size_-2);
    text-align: left;
    white-space: nowrap;

    .Profile-info-username {
      color: var(--color-primary-text);
      font-size: var(--font-size__0);
      font-weight: 500;
      line-height: 1.5;
    }
  }

  .user-menu-button {
    font-size: 9px;

    &:active,
    &:focus,
    &:hover {
      box-shadow: none;
    }
  }
}
