// Copyright 2022 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

.leaflet-pane {
  z-index: 4;

  .concentration-tiles {
    img {

      /* Preserve crisp pixels with scaled up images */
      image-rendering: optimizeSpeed;             /* Legal fallback */
      image-rendering: -moz-crisp-edges;          /* Firefox        */
      image-rendering: -o-crisp-edges;            /* Opera          */
      image-rendering: -webkit-optimize-contrast; /* Safari         */
      image-rendering: optimize-contrast;         /* CSS3 Proposed  */
      image-rendering: crisp-edges;               /* CSS4 Proposed  */
      image-rendering: pixelated;                 /* CSS4 Proposed  */
      -ms-interpolation-mode: nearest-neighbor;   /* IE8+           */
    }
  }
}

.leaflet-bottom.leaflet-right {
  display: flex;
  flex-flow: row;
  margin-bottom: 3rem;

  .leaflet-control-zoom {
    margin-right: 1.75rem;
    border: unset;
    border-radius: 0;

    a.leaflet-control-zoom-in,
    a.leaflet-control-zoom-out {
      display: flex;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      background-color: var(--surface-a);
      box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

      span {
        display: flex;
        width: 14px;
        height: 18px;
        align-items: center;
      }
    }
  }

  .leaflet-control-scale {
    align-self: flex-end;

    :first-child {
      border-top: none;
      border-bottom: 2px solid var(--color-orbit-blue);
    }
  }

  .leaflet-control-scale-line {
    bottom: -1px;
    border: 2px solid var(--color-orbit-blue);
    border-bottom: none;
    color: var(--color-orbit-blue);
    font-weight: 600;
  }
}
