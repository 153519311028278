/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
@mixin colors {
  &.surface-a {
    color: var(--surface-a);

    &:hover {
      color: var(--surface-e);
    }
  }
}
