/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.g-prime-override {
  .p-tabmenu {
    .p-tabmenu-nav {
      border: $tabviewNavBorder;
      border-width: $tabviewNavBorderWidth;
      background: $tabviewNavBg;

      .p-tabmenuitem {
        overflow: hidden;

        &:first-child {
          border-radius: 6px 0 0 0;
        }

        &:last-child {
          border-radius: 0 6px 0 0;
        }
      }
    }
  }
}
