/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.g-esri-override {
  .p-toast {
    top: var(--top-bar-height);
    left: 0;
    width: 100%;

    .p-toast-message {
      margin: 0;
      background-color: var(--surface-a);

      &.p-toast-message-info {
        border-bottom: 2px solid currentColor;
        color: var(--color-primary);
      }

      &.p-toast-message-warn {
        border-bottom: 2px solid currentColor;
        color: var(--color-yellow);
      }

      &.p-toast-message-error {
        border-bottom: 2px solid currentColor;
        color: var(--color-red);
      }

      &.p-toast-message-success {
        border-bottom: 2px solid currentColor;
        color: var(--color-green);
      }

      .p-toast-message-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--space-mouse) var(--space-hamster)  var(--space-mouse) var(--space-puppy);
      }

      .p-toast-icon-close {
        color: var(--color-text);
      }
    }
  }
}
