/**
*
* Copyright 2022 GHGSat inc.
* Authors: spectra@ghgsat.com
* This software is not for distribution outside GHGSat organization
*
*/

.timeline-date {
  display: flex;
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  padding: 8px 16px;
  color: var(--color-primary);
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  &:hover {
    background-color: var(--color-primary-100);
    cursor: pointer;
  }

  .g-field-start-icon {
    padding-right: 8px;

    svg {
      width: 16px;
      height: 16px;
      pointer-events: none;

      path {
        fill: var(--color-primary);
      }
    }
  }
}
