/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.password-hint {
  min-width: 250px;
  transform: translateX(2px);

  li {
    display: block;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
