/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.g-esri-override {
  .g-modal-window {
    max-height: 70vh;

    .modal-toast-container {
      position: relative;

      .p-toast {
        position: absolute;
        top: 0;
      }
    }

    .modal-content-container {
      display: flex;
      max-height: calc(70vh - var(--dialog-header-height));
      flex-direction: column;
    }

    &.p-dialog .p-dialog-header {
      display: grid;
      height: var(--dialog-header-height);
      align-content: center;
      background-color: var(--color-grey-disabled);

      .cancel-click {
        position: absolute;
        top: 1em;
        right: 1.5em;
        border: none;
        background: none;
      }

      .p-button-outlined {
        position: absolute;
        top: 0.5em;
        right: 2em;
        text-transform: uppercase;
      }

      .p-dialog-header-icons {
        display: none;
      }
    }
  }
}
