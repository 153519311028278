/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.g-tooltip {
  display: flex;
  padding: 10px;
  background-color: var(--color-primary-background);
  color: white;
  font-size: var(--font-size_-2);
  -webkit-animation: fadein 300ms;
  -moz-animation: fadein 300ms;
  -o-animation: fadein 300ms;
  -ms-animation: fadein 300ms;
  animation: fadein 300ms;

  &.sm {
    width: 200px;
  }

  &.md {
    width: 300px;
  }

  &.lg {
    width: 500px;
  }

  @keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  &.left {
    align-items: center;

    .g-arrow {
      transform: translate(5px, -5px);
    }
  }

  &.right {
    flex-direction: row-reverse;
    align-items: center;

    .g-arrow {
      transform: translate(-15px, -5px);
    }
  }

  &.bottom {
    flex-direction: column-reverse;
    align-items: center;

    .g-arrow {
      transform: translate(-5px, -15px);
    }
  }

  &.top {
    flex-direction: column;
    align-items: center;

    .g-arrow {
      transform: translate(-5px, 5px);
    }
  }

  .g-arrow {
    position: relative;
    width: 0;
    height: 0;
    visibility: hidden;
    background-color: inherit;
  }

  .g-arrow::before {
    position: absolute;
    width: 10px;
    height: 10px;
    visibility: visible;
    transform: rotate(45deg);
    background: inherit;
    content: '';
  }
}
