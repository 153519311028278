/* src/app/Assets/Styles/GhgSat/overrides/Prime/_overlaypanel.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

// Local variables
$Base-Spacer: var(--space_-1);
$Small-Spacer: var(--space_-3);
$HeaderHeight: var(--space__2);
$FooterHeight: calc(var(--space__2) + 10px);
$BorderColor: var(--color-grey--500);
$TextBaseline: var(--space_-1);

// Let's give a try at some Dialog size names
:root {
  --dialog-whisper-w: 132px;
  --dialog-tell-w: 300px;
  --dialog-speak-w: 300px;
  --dialog-reveal-w: 420px;
  --dialog-notify-w: 470px;
  --dialog-ask-w: 480px;
  --dialog-parley-w: 800px;
  --dialog-shout-w: 100vw;
  // A height may be needed
  --dialog-softly-h: auto;
  --dialog-ask-h: 315px;
  --dialog-notify-h: 580px;
  --dialog-parley-h: 667px;
  --dialog-shout-h: 100vw;
}

.g-esri-override {
  .p-overlaypanel {
    transform: translate(33px, 0);

    &.p-overlaypanel-arrow-right {
      &::before,
      &::after {
        left: 95%;
      }
    }
  }

  .g-Dialog {
    width: auto;
    margin: 0;
    background-color: var(--surface-a);
    grid-template-columns: 100%;
    grid-template-rows: $HeaderHeight 1fr;
    grid-template-areas:
      "header"
      "body";

    &.bottom-left {
      margin-bottom: 20px;
      margin-left: 20px;
    }

    &.large-popup {
      .p-dialog-header {
        flex-direction: row;
        align-items: center;
        padding: $Small-Spacer;
        background-color: var(--color-grey-disabled);
        color: var(--color-black);

        .p-dialog-title {
          font-size: var(--font-size__0);
          font-weight: var(--font-weight-bold);
        }

        .p-Dialog-header-icon {
          justify-content: flex-end;
        }
      }
    }

    &.is-softly {
      width: var(--dialog-whisper);
    }

    &.is-tell {
      width: var(--dialog-tell-w);
    }

    &.is-reveal {
      width: var(--dialog-reveal-w);
    }

    &.is-ask {
      width: var(--dialog-ask-w);
      min-height: var(--dialog-ask-h);
    }

    &.is-notify {
      // This format has a fixed height and can scroll
      width: var(--dialog-notify-w);
      height: var(--dialog-notify-h);
    }

    &.is-parley {
      width: var(--dialog-parley-h);
      height: var(--dialog-parley-h);
    }

    &.is-shout {
      width: var(--dialog-shout-h);
      height: var(--dialog-shout-h);
    }

    &.has-footer {
      grid-template-rows: $HeaderHeight calc(100% - (#{$HeaderHeight} + #{$FooterHeight})) $FooterHeight;
      grid-template-areas:
        "header"
        "body"
        "footer";
    }
  }

  .g-Dialog-Header,
  .p-dialog-header {
    display: flex;
    height: fit-content;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 2px solid var(--surface-g);
    padding: 0 $Small-Spacer;
    background-color: var(--surface-a);
    color: var(--color-text);
    grid-area: header;
  }

  .p-Dialog-header .p-Dialog-title {
    font-family: var(--font-family);
    font-size: var(--font-size__0);
    font-weight: var(--font-weight-bold);
  }

  .p-Dialog .p-Dialog-header .p-Dialog-header-icon {
    color: currentColor;
  }

  .p-dialog-content {
    padding: 0;

    .g-Dialog-Body {
      height: 100%;
      overflow: auto;
      padding: $Small-Spacer;
      color: var(--color-text);
      font-size: var(--font-size_-2);
      font-weight: var(--font-weight-normal);
      line-height: $Base-Spacer;
      grid-area: body;

      ._h3 {
        font-family: var(--font-family);
      }

      &.has-twoCols {
        display: grid;
        grid-template-columns: 55fr 45fr;
        grid-template-rows: 1fr;
        column-gap: $Base-Spacer;
      }

      .g-Dialog-Body-Paragraph {
        margin: var(--space-hamster) 0 var(--space) 0;
        color: var(--color-text);
        font-family: var(--font-family);
        font-size: var(--font-size_-2);
        font-weight: var(--font-weight-normal);
        letter-spacing: var(--tracking-fit);
      }

      .g-Dialog-Body-Media {
        margin: var(--space-hamster) 0 var(--space) 0;
      }
    }
  }

  .p-dialog-footer {
    display: flex;
    position: sticky;
    bottom: 0;
    z-index: 1;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
    background-color: var(--color-grey--50);
  }

  .g-Dialog .g-Dialog-Footer {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid var(--color-grey--300);
    padding: 0 $Small-Spacer;
    grid-area: footer;
  }
}
