/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.basic-button {
  cursor: pointer;

  &.p-button {
    text-transform: uppercase;

    &.disabled {
      border: none;
      background-color: var(--color-grey--500);
      color: var(--color-primary-text);
    }

    &.p-button-no-outline {
      border: none;
      background-color: transparent;
      color: var(--color-primary);
    }
  }

  &.g-link-button {
    color: var(--color-primary);
    text-decoration: underline;

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
