/**
 * /* src/app/Assets/Styles/GhgSat/objects/_layout.scss
 *
 * @format
 */

// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

// For somme interesting reasons we don't have time to explain now,
// we need some Sass vars equivalent to our css custom properties
//
// Basic Layout
$header-height: 60px;
$appbar-length: 40px;
$rightbar-width: 465px;
$rightbar-border-width: 8px;
$top-bar-height: 56px;
$tabmenu-height: 40px;
$footer-height: 25px;

// Reports-download widget
$reports-download-widget-height: 50px;

:root {
  --min-map-height: 360px;
  --header-height: 96px;
  --appbar-length: 40px;
  --left-panel-width: 300px;
  --right-panel-width: 465px;
  --right-panel-border-width: 8px;
  --top-bar-height: 56px;
  --tabmenu-height: 40px;
  --footer-height: 25px;
  --reports-download-widget-height: #{$reports-download-widget-height};
  --appbar-btn-length: 34px;
  --g-datatable-header-height: 40px;
  --g-datatable-bottom-buttons-height: 50px;
  --g-datatable-paginator-height: 47px;
  --g-datatable-row-height: 45px;
  --g-datatable-cell-padding: 0 1.5rem 0 0.5rem;
  --datatable-header-height-expanded: 92px;
  --datatable-header-height-collapsed: 52px;
  --reports-download-height: 50px;
  --g-timeline-panel-height: 200px;
  --dialog-header-height: 55px;
  --bookmarks-modal-grid-columns: 40px 260px 4fr 4fr 3fr 100px 40px;
}

.g-Layout__app-window,
.g-Layout-AppWindow {
  display: grid;
  width: 100vw;
  height: 100vh;
  min-height: calc(var(--min-map-height) + var(--top-bar-height) + var(--tabmenu-height) + var(--footer-height));
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows:
    var(--top-bar-height)
    var(--tabmenu-height)
    1fr
    var(--footer-height);
  grid-auto-columns: 0;
  grid-template-areas: 'masthead' 'tabview' 'main' 'footer';
  gap: 0;

  &.public {
    grid-template-rows: auto;
    grid-template-areas: none;
  }

  &.hide-tab-area {
    grid-template-rows:
      var(--top-bar-height)
      calc(100vh - var(--top-bar-height) - var(--footer-height))
      var(--footer-height);
    grid-template-areas: 'masthead' 'main' 'footer';
  }
}

.g-Layout__app-window-content,
.g-Layout-AppWindowContent {
  width: 100%;
  background-color: var(--color-grey--300);
}

.g-Layout-Body {
  display: grid;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - var(--top-bar-height) - var(--tabmenu-height) - var(--footer-height));
  grid-template-rows: 1fr;
  gap: 0;

  &.g-View--map {
    grid-template-columns: 1fr;
    grid-template-areas: 'view';
  }

  &.g-View--table {
    grid-template-columns: 1fr;
    grid-template-areas: 'view';
  }
}

.g-Layout-View {
  &.is-visible {
    display: grid;
    width: 100%;
    height: 100%;
    grid-area: view;
  }

  &.is-hidden {
    display: none;
  }

  &.g-Map-View {
    grid-template-columns: var(--appbar-length) 1fr var(--rightbar-width);
    grid-template-areas: 'appbar mapview rightbar';

    &.right-bar-hidden {
      grid-template-columns: var(--appbar-length) 1fr var(--rightbar-border-width);
      transition: 2s;
    }
  }
}

[class*='Grid-Layout'] {
  display: grid;
  column-gap: var(--vertical-grid-baseline);
  row-gap: 0;
}

.Grid-Layout--cols-2-10 {
  grid-template-columns: 180px 10fr;
  grid-template-rows: 1fr;
  grid-auto-rows: 0;
}

.Grid-Layout--cols-1-1 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-rows: 0;
}

.Grid-Layout--cols-9-1 {
  grid-template-columns: 9fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-rows: 0;
}

.Grid-Layout--cols-2-auto-2-5 {
  grid-template-columns: 2fr auto 2fr;
  grid-template-rows: 1fr;
  grid-auto-rows: 0;
}

.Grid-Layout--Baseline-row {
  align-items: center;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(auto, var(--vertical-grid-baseline));
}
