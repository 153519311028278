/**
 * /*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

.g-third-party-satellites-filter-li {
  display: block;
  height: max-content;
}

.g-third-party-satellites-filter-container {
  display: grid;
  height: 100%;
  margin: 0.1em 3em 0.1em 4em;
  color: var(--color-primary-text);
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5em;

  .g-third-party-filter-button {
    border: none;
    border-radius: 4px;
    padding: 0.2625rem 0.4125rem;
    background-color: var(--color-grey--500);
    color: var(--color-primary-text);
    font-family: var(--font-family-narrow);
    font-size: 0.75rem;
    font-weight: var(--font-weight-normal);

    &.isActive {
      background-color: var(--color-primary);
      text-align: center;

      &:hover {
        background-color: var(--color-primary);
      }
    }

    &.p-disabled {
      opacity: 1;
      background-color: var(--color-grey--500);
    }

    &:hover {
      background-color: var(--color-grey--500);
    }
  }
}
