// Copyright 2021 GHGSat inc.
// Authors: software@ghgsat.com
// This software is not for distribution outside GHGSat organization

.p-confirm-popup {
  margin-top: var(--space_-3);
  border: 0 none;
  border-radius: --space_-4;
  background: $white;
  color: var(--color-text);
  box-shadow: 0 0 var(--space) var(--space_-4) rgba(0, 0, 0, 0.15);

  &::before {
    border: var(--space_-3) solid transparent;
    border-bottom-color: $white;
  }

  .p-confirm-popup-content {
    padding: var(--space_-2);
  }

  .p-confirm-popup-message {
    margin-left: var(--space_-2);
  }

  .p-confirm-popup-footer {
    padding: 0 var(--space_-2) var(--space_-2) var(--space_-2);
    text-align: right;
  }

  .p-confirm-popup-reject,
  .p-confirm-popup-accept {
    width: auto;
    margin: 0 var(--space_-3) 0 0;
    padding: var(--space_-4) var(--space_-2);
    font-size: var(--font-size__0);
  }
}
