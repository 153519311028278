/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.g-esri-override {
  .password-section {
    &.password-success {
      display: grid;
      align-content: center;
      justify-content: center;

      .header {
        padding: 10px;
        color: var(--color-text);
        font-weight: var(--font-weight-bold);
        text-align: center;
      }

      .message {
        color: var(--color-text-input);
        font-size: var(--font-size_-1);
      }
    }
  }
}
