/* src/app/Assets/Styles/vendors/Prime/themes/designer/_common.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

* {
  box-sizing: border-box;
}

.p-component {
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: normal;
}

.p-component-overlay {
  background-color: $maskBg;
  transition-duration: $transitionDuration;
}

.p-disabled,
.p-component:disabled {
  opacity: $disabledOpacity;
}

.p-error,
.p-invalid {
  color: $errorColor;
}

.p-text-secondary {
  color: $textSecondaryColor;
}
