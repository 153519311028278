/* src/app/Assets/Styles/ghgSat/overrides/Prime/_badges.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

.p-tag {
  border-radius: 2px;

  &.p-tag-success {
    background-color: transparentize($successButtonBg, 0.8);
    color: $successButtonBg;
  }

  &.p-tag-info {
    background-color: transparentize($infoButtonBg, 0.8);
    color: $infoButtonBg;
  }

  &.p-tag-warning {
    background-color: transparentize($warningButtonBg, 0.8);
    color: $warningButtonBg;
  }

  &.p-tag-danger {
    background-color: transparentize($dangerButtonBg, 0.8);
    color: $dangerButtonBg;
  }
}
