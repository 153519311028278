/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.g-esri-override {
  .p-progress-spinner {
    width: 20px;
    height: 20px;
    margin-right: 5px;

    .p-progress-spinner-circle {
      stroke: #ffffff;
      animation: none;
    }

    &.primary {
      .p-progress-spinner-circle {
        stroke: var(--color-primary);
        animation: none;
      }
    }
  }
}
